var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invitedUsers,"sort-by":"email"},scopedSlots:_vm._u([{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.type === "SURVEY" ? "Sólo responder encuesta" : "Acceso a la plataforma")+" ")]}},{key:`item.survey`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.invitationAccepted ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(!item.invitationAccepted ? "Pendiente" : "Realizada")+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#7319D5","disabled":item.invitationAccepted},on:{"click":() => {
              _vm.reSendItem(item);
              _vm.setOpenModal(true);
              _vm.setModalState(3);
            }}},[_vm._v(" mdi-email ")]),_c('v-icon',{staticClass:"ml-3",attrs:{"color":"#7319D5","disabled":item.invitationAccepted},on:{"click":() => {
              _vm.setItemFromTable(item);
              _vm.setOpenModal(true);
              _vm.setModalState(2);
            }}},[_vm._v(" mdi-delete ")])]}}],null,true)})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }