<template>
  <div>
    <v-img :src="require('@/assets/img/invited/team.png')" max-width="500px" max-height="427px" />
    <div class="tway-header-2 tway-gray-dark--text mb-4" style="text-align: center; max-width: 514px">
      Agrega a los invitados que accederán a responder la encuesta Tway de Madurez en Transformación Digital
    </div>

    <v-row class="ma-0" justify="center">
      <v-btn depressed rounded color="tway-violet" class="button-text white--text" @click.stop="setOpenModal(true)">
        <span style="text-transform: uppercase"> + agregar invitados</span>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("InvitationsStore", ["setOpenModal"]),
  },
};
</script>

<style></style>
