<template>
  <v-main>
    <Breadcrumbs :items="breadcrumbs" />
    <Loader :visible="isLoading" />
    <v-row class="mb-10" v-if="!isLoading">
      <v-col cols="6">
        <div class="tway-header-1 mb-6" style="font-weight: 400">Invitados</div>
      </v-col>
      <v-col cols="6" v-if="invitedsQuantity !== 0 && desktop" class="d-flex justify-end">
        <v-btn @click="setOpenModal(true)" depressed rounded class="button-text tway-violet white--text mt-5">
          <span style="text-transform: uppercase"> + agregar invitados</span>
        </v-btn>
      </v-col>
    </v-row>

    <InvitedsModal :client-id="getClientId" :user-id="getUserId" :available-seats="userSeats" @loading="loading" />
    <div v-if="!isLoading">
      <v-row justify="center" v-if="invitedsQuantity === 0">
        <v-col class="pa-0 ma-0" style="max-width: 1000px" align="center">
          <NoInviteds />
        </v-col>
      </v-row>
      <v-row justify="center" style="width: auto" v-else>
        <v-col cols="12">
          <InvitationCards
            :available-seats="userSeats"
            :completed-surveys="completedSurveys"
            :registered-users="registeredUsers"
          />
        </v-col>
        <v-col cols="12" class="mt-3">
          <InvitationsTable
            v-if="invitedsQuantity !== 0"
            :invited-users="getInvitedUsers"
            :client-id="getClientId"
            :user-id="getUserId"
          />
        </v-col>
      </v-row>

      <v-fab-transition v-if="invitedsQuantity != 0 && mobile">
        <v-btn
          color="#7319D5"
          dark
          fixed
          bottom
          right
          fab
          x-large
          :class="mobile ? 'mb-15' : ''"
          @click="
            () => {
              this.setModalState(0);
              this.setOpenModal(true);
            }
          "
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </v-main>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import Loader from "@/components/Loader";
import InvitationsTable from "@/components/invitations/invitationsTable";
import InvitationCards from "@/components/invitations/invitationCards";
import InvitedsModal from "@/components/invitations/invitedsModal";
import NoInviteds from "@/components/invitations/noInviteds";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Breadcrumbs,
    Loader,
    InvitationsTable,
    InvitedsModal,
    InvitationCards,
    NoInviteds,
  },
  async created() {
    await Promise.all([this.setSubscription(), this.setInvitedUsers(this.getClientId)]).then(
      () => (this.isLoading = false)
    );
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Inicio",
          disabled: false,
          exact: true,
          to: {
            name: "home",
          },
        },
        {
          text: "Invitados",
          disabled: true,
          exact: true,
          to: {
            name: "inviteds",
          },
        },
      ],
      isLoading: true,
      invitedUsersQuantity: 0,
    };
  },
  methods: {
    ...mapActions("InvitationsStore", ["setOpenModal", "setModalState", "setInvitedUsers"]),
    ...mapActions("subscription", ["setSubscription"]),
    loading(event) {
      this.isLoading = event;
    },
  },
  computed: {
    ...mapGetters("UserStore", ["getClientId", "getUserId"]),
    ...mapGetters("subscription", ["getSubscription"]),
    ...mapGetters("InvitationsStore", ["getModalState", "getOpenModal", "getInvitedUsers"]),
    registeredUsers() {
      const registered = this.getInvitedUsers.filter((e) => e.userId !== null).length;
      return registered;
    },
    completedSurveys() {
      const completed = this.getInvitedUsers.filter((e) => e.invitationAccepted === true).length;
      return completed;
    },
    userSeats() {
      if (this.getSubscription.plan != undefined) {
        const planSizes = this.getSubscription.plan.size;
        const { userSurveys, users } = planSizes;
        const surveyInviteds = this.getInvitedUsers.filter((e) => e.type == "SURVEY").length;
        const userInviteds = this.getInvitedUsers.filter((e) => e.type == "USER").length;
        const result = {
          surveys: userSurveys - surveyInviteds,
          users: users - userInviteds,
        };
        return result;
      } else {
        return {};
      }
    },
    invitedsQuantity() {
      return this.getInvitedUsers.length;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style></style>
