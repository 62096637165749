<template>
  <v-container>
    <template>
      <v-data-table :headers="headers" :items="invitedUsers" sort-by="email" class="elevation-1">
        <template v-slot:[`item.type`]="{ item }">
          {{ item.type === "SURVEY" ? "Sólo responder encuesta" : "Acceso a la plataforma" }}
        </template>
        <template v-slot:[`item.survey`]="{ item }">
          <v-chip :color="item.invitationAccepted ? 'green' : 'red'" dark>
            {{ !item.invitationAccepted ? "Pendiente" : "Realizada" }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="mr-2"
            @click="
              () => {
                reSendItem(item);
                setOpenModal(true);
                setModalState(3);
              }
            "
            color="#7319D5"
            :disabled="item.invitationAccepted"
          >
            mdi-email
          </v-icon>
          <v-icon
            @click="
              () => {
                setItemFromTable(item);
                setOpenModal(true);
                setModalState(2);
              }
            "
            color="#7319D5"
            class="ml-3"
            :disabled="item.invitationAccepted"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import invitationsDataTableHeaders from "@/utils/invitationsDataTableHeaders";
export default {
  props: {
    invitedUsers: {
      type: Array,
      default: () => [],
      required: true,
    },
    clientId: {
      type: String,
      default: "",
      required: true,
    },
    userId: {
      type: String,
      default: "",
      required: true,
    },
  },
  data: () => ({
    headers: invitationsDataTableHeaders,
  }),

  methods: {
    ...mapActions("InvitationsStore", [
      "setModalState",
      "setOpenModal",
      "setItemFromTable",
      "setInvitedUsers",
      "deleteInvitedUsers",
      "reSendInvitation",
      "cleanInvitations",
    ]),
    async reSendItem(item) {
      await this.reSendInvitation(item.id).then(() => {
        this.setOpenModal(true);
        this.setModalState(3);
      });
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style></style>
