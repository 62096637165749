<template>
  <v-dialog
    v-model="getOpenModal"
    @click:outside="
      () => {
        this.userInvitations = [];
        setOpenModal(false);
      }
    "
    @keyup.esc="
      () => {
        this.userInvitations = [];
        setOpenModal(false);
      }
    "
    max-width="700"
  >
    <v-card v-if="getModalState === 0">
      <v-card-title class="text-h5"> Agregar Invitados </v-card-title>
      <v-card-subtitle class="mt-1">
        Puedes invitar hasta: <br />
        <b>{{ availableSeats.users - selectedUsers }} usuarios con acceso a la plataforma</b>
        <br />
        <b> {{ availableSeats.surveys - selectedSurveys }} usuarios para responder la encuesta </b>
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row v-for="(invitation, index) in userInvitations" :key="index">
            <v-col :cols="mobile ? '10' : '5'">
              <span>Correo corporativo</span>
              <v-text-field
                class="mt-1"
                v-model="userInvitations[index].email"
                :rules="[required('correo'), validateEmail()]"
                label="Escribe un correo corporativo aquí"
                dense
                required
                outlined
                :color="validationError ? '#b71c1c' : '#7319D5'"
                ref="correo"
                autofocus
              ></v-text-field>
            </v-col>
            <v-col :cols="mobile ? '10' : '5'" :class="mobile ? 'mt-n5' : ''">
              <span>Acceso a la plataforma</span>
              <v-autocomplete
                dense
                solo
                class="mt-1"
                v-model="userInvitations[index].type"
                @change="validateSeats"
                :rules="[required('acceso')]"
                :items="accessTypes"
                item-text="string"
                item-value="type"
                item-disabled="disable"
                label="Selecciona un tipo de acceso"
                required
                outlined
                :color="validationError ? '#b71c1c' : '#7319D5'"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="userInvitations[index].type === 'SURVEY'" :cols="mobile ? '2' : '2'" align-self="center">
              <v-radio-group v-model="userInvitations[index].surveyIndex">
                <v-radio :label="'IMO'" :value="'IMO'"></v-radio>
                <v-radio :label="'IMTD'" :value="'IMTD'"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col :cols="mobile ? '2' : '2'" align-self="center">
              <div v-if="index > 0">
                <v-icon @click="userInvitations.splice(index, 1)" color="#7319D5" class="ml-3"> mdi-delete </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mt-6" v-if="availableInvitations">
          <button class="ml-5" @click="addAnotherInvited()">
            <v-row align="center"
              ><h1>+</h1>
              <h5 style="margin-left: 7px">
                {{ userInvitations.length === 0 ? "AGREGAR UN USUARIO" : "AGREGAR OTRO USUARIO" }}
              </h5>
            </v-row>
          </button>
        </v-row>
        <v-row v-if="validationError" class="mt-10" style="color: #b71c1c; text-align: center">
          <v-col cols="12" class="mb-n9">
            <p>Ha ocurrido un error al intentar enviar las invitaciones.</p>
          </v-col>
          <v-col cols="12"
            ><p>
              Si el problema continúa, por favor contáctanos desde nuestro correo
              <a href:mailto="soporte@twaydigital.com" style="color: #b71c1c">soporte@twaydigital.com</a>
            </p></v-col
          >
        </v-row>
      </v-card-text>
      <v-container class="pa-5 mt-6">
        <v-row justify="center" class="mb-5" v-if="mobile">
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              @click="sendInvitation()"
              :disabled="valid != false && userInvitations.length > 0 ? false : true"
              depressed
              rounded
              class="button-text tway-violet white--text"
            >
              {{ userInvitations.length > 1 ? "Enviar invitaciones" : "Enviar invitación" }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              outlined
              color="deep-purple accent-4"
              class="button-text"
              rounded
              @click="
                () => {
                  this.setOpenModal(false);
                  this.userInvitations = [];
                  this.validationError = false;
                }
              "
              id="profile-nextstep-back-from-complete"
            >
              cancelar
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-5" v-else>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              outlined
              color="deep-purple accent-4"
              class="button-text"
              rounded
              @click="
                () => {
                  setOpenModal(false);
                  this.userInvitations = [];
                  this.validationError = false;
                }
              "
              id="profile-nextstep-back-from-complete"
            >
              cancelar
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              @click="sendInvitation()"
              :disabled="valid != false && userInvitations.length > 0 ? false : true"
              depressed
              rounded
              class="button-text tway-violet white--text"
            >
              {{ userInvitations.length > 1 ? "Enviar invitaciónes" : "Enviar invitación" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="getModalState === 1">
      <v-container>
        <v-row align="center" justify="center" class="mt-6">
          <v-img :src="require('@/assets/img/mdi-check-circle.png')" max-width="73px" max-height="73px" />
        </v-row>
        <v-container style="max-width: 500px; text-align: center">
          <v-card-text
            class="mt-5"
            style="
              font-family: Josefin Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 25px;
              line-height: 30px;
              color: #333333;
            "
          >
            Las invitaciones se han realizado exitosamente. Los usuarios invitados recibirán una notificación con la
            información correspondiente.
          </v-card-text>
        </v-container>
        <v-row justify="center" class="mb-5 mt-2">
          <v-btn
            outlined
            class="button-text tway-violet white--text"
            rounded
            @click="
              () => {
                setOpenModal(false);
                setModalState(0);
                this.userInvitations = [];
                updateTable();
              }
            "
          >
            aceptar
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="getModalState === 2">
      <v-container>
        <v-container style="max-width: 500px; text-align: center">
          <p
            class="mt-5"
            style="
              font-family: 'Josefin Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 30px;
              line-height: 150%;
              text-align: center;
              color: #333333;
            "
          >
            ¿Realmente quieres eliminar esta invitación?
          </p>
          <p
            class="mt-5"
            style="
              font-family: Josefin Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 24px;
              line-height: 30px;
              color: #333333;
            "
          >
            Al eliminar una invitación, el correo que ha sido enviado expirará.
          </p>
        </v-container>
        <v-row justify="center" class="mb-5 mt-2">
          <v-btn
            outlined
            class="button-text transparent mr-10"
            rounded
            @click="
              () => {
                setOpenModal(false);
                setModalState(0);
              }
            "
          >
            volver
          </v-btn>
          <v-btn
            outlined
            class="button-text tway-violet white--text"
            rounded
            @click="
              () => {
                deleteItem();
              }
            "
          >
            sí, eliminar
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="getModalState === 3">
      <v-container>
        <v-row align="center" justify="center" class="mt-6">
          <v-img :src="require('@/assets/img/mdi-check-circle.png')" max-width="73px" max-height="73px" />
        </v-row>
        <v-container style="max-width: 500px; text-align: center">
          <v-card-text
            class="mt-5"
            style="
              font-family: Josefin Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 25px;
              line-height: 30px;
              color: #333333;
            "
          >
            Las invitaciones se han reenviado exitosamente. Los usuarios invitados recibirán una notificación con la
            información correspondiente.
          </v-card-text>
        </v-container>
        <v-row justify="center" class="mb-5 mt-2">
          <v-btn
            outlined
            class="button-text tway-violet white--text"
            rounded
            @click="
              () => {
                updateTable();
                setOpenModal(false);
                setModalState(0);
              }
            "
          >
            aceptar
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidatorsService from "@/services/validatorsService";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    clientId: {
      type: String,
      default: "",
      required: true,
    },
    userId: {
      type: String,
      default: "",
      required: true,
    },
    availableSeats: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      userInvitations: [],
      selectedUsers: 0,
      selectedSurveys: 0,
      valid: false,
      validationError: false,
    };
  },
  methods: {
    ...mapActions("InvitationsStore", [
      "setModalState",
      "setOpenModal",
      "addInvitations",
      "reSendInvitation",
      "deleteInvitedUsers",
      "setInvitedUsers",
    ]),
    required: (propertyType) => ValidatorsService.required(propertyType),
    validateEmail: () => ValidatorsService.validateEmail(),
    addAnotherInvited() {
      this.userInvitations.push({
        email: "",
        type: "",
        //TODO: add survey
      });
    },
    async sendInvitation() {
      let payload = [];
      this.userInvitations.forEach((element) => {
        element.userId = this.userId;
        element.clientId = this.clientId;
        //TODO: ADD survey
        payload.push(element);
      });
      await this.addInvitations(payload).then(() => {
        this.validateInvitedUsers();
      });
    },
    validateInvitedUsers() {
      this.userInvitations = [];
      if (this.getInvitations.length > 0) {
        this.getInvitations.forEach((element) => {
          let email = element.email;
          let type = element.type;
          //TODO: validate surveyIndex
          if (element.error) {
            this.userInvitations.push({
              email,
              type,
            });
            this.validationError = true;
            this.setModalState(0);
          }
          if (this.userInvitations.length === 0) {
            this.setModalState(1);
          }
        });
      }
    },
    async deleteItem() {
      await this.deleteInvitedUsers(this.getItemFromTable.id)
        .then(() => {
          this.setOpenModal(false);
          this.setModalState(0);
          this.updateTable();
        })
        .catch((err) => err);
    },

    async updateTable() {
      this.$emit("loading", true);
      this.setInvitedUsers(this.clientId);
      setTimeout(() => {
        this.$emit("loading", false);
      }, 500);
    },
    validateSeats() {
      this.selectedUsers = this.userInvitations.filter((e) => e.type == "USER").length;
      this.selectedSurveys = this.userInvitations.filter((e) => e.type == "SURVEY").length;
    },
  },
  watch: {
    userInvitations() {
      this.validateSeats();
    },
  },
  computed: {
    ...mapGetters("InvitationsStore", ["getModalState", "getOpenModal", "getInvitations", "getItemFromTable"]),
    accessTypes() {
      let accessTypes = [
        {
          type: "SURVEY",
          string: "Sólo responder encuesta",
          disable: this.availableSeats.surveys - this.selectedSurveys == 0 ? true : false,
        },
        {
          type: "USER",
          string: "Acceso a la plataforma",
          disable: this.availableSeats.users - this.selectedUsers == 0 ? true : false,
        },
      ];
      return accessTypes;
    },
    availableInvitations() {
      const { surveys, users } = this.availableSeats;
      const total = surveys + users - this.userInvitations.length;
      return total;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style></style>
