<template>
  <v-row>
    <v-col :cols="mobile ? '6' : '4'">
      <v-card color="#7319d5" height="150px" style="border-radius: 10px">
        <v-row class="">
          <v-col cols="6" align-self="center">
            <v-row
              ><div class="ml-4 mt-2">
                <span class="titleCard1"> Invitaciones disponibles: </span>
              </div></v-row
            >
            <v-row
              ><div class="ml-4">
                <div class="mt-1">
                  <span class="numberCard1">{{ availableSeats.users }}</span>
                  <span class="subtitleCard1">con acceso a la plataforma</span>
                </div>
              </div></v-row
            >
            <v-row
              ><div class="ml-4">
                <div class="mt-1">
                  <span class="numberCard1">{{ availableSeats.surveys }}</span>
                  <span class="subtitleCard1">para responder la encuesta</span>
                </div>
              </div></v-row
            >
          </v-col>
          <v-col cols="6" class="d-flex justify-space-around"
            ><v-img
              :src="require('@/assets/img/invited/invited_user.png')"
              :max-width="mobile ? '40px' : '73px'"
              :max-height="mobile ? '40px' : '73px'"
          /></v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col :cols="mobile ? '6' : '4'">
      <v-card color="#7319d5" height="150px" style="border-radius: 10px" class="">
        <v-row class="">
          <v-col cols="6" align-self="center">
            <div class="ml-4">
              <span class="cardNumberDetail mt-1">
                {{ completedSurveys }}
              </span>
            </div>
          </v-col>
          <v-col cols="6" class="d-flex justify-space-around"
            ><v-img
              :src="require('@/assets/img/invited/invited_check.png')"
              :max-width="mobile ? '40px' : '73px'"
              :max-height="mobile ? '40px' : '73px'"
          /></v-col>
        </v-row>
        <v-row justify="center">
          <v-col :cols="mobile ? '10' : '12'">
            <p style="color: #ffffff" :class="mobile ? '' : 'ml-5'">Encuestas realizadas</p>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col :cols="mobile ? '6' : '4'">
      <v-card color="#7319d5" height="150px" style="border-radius: 10px" class="">
        <v-row class="">
          <v-col cols="6" align-self="center">
            <div class="ml-4">
              <span class="cardNumberDetail mt-1">
                {{ registeredUsers }}
              </span>
            </div>
          </v-col>
          <v-col cols="6" class="d-flex justify-space-around"
            ><v-img
              :src="require('@/assets/img/invited/invited_ready.png')"
              :max-width="mobile ? '40px' : '73px'"
              :max-height="mobile ? '40px' : '73px'"
          /></v-col>
        </v-row>
        <v-row justify="center">
          <v-col :cols="mobile ? '10' : '12'">
            <p style="color: #ffffff" :class="mobile ? 'mb-2' : 'ml-5'">Invitados en la plataforma</p>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    availableSeats: {
      type: Object,
      default: () => {},
      required: true,
    },
    completedSurveys: {
      type: Number,
      default: 0,
      required: true,
    },
    registeredUsers: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    ...mapGetters("InvitationsStore", ["getInvitedUsers"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style scoped>
.cardNumberDetail {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 68px;
  line-height: 100%;
  color: #ffffff;
}
@media only screen and (max-width: 600px) {
  .cardNumberDetail {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 100%;
    color: #ffffff;
  }
}
.numberCard1 {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  /* shades / white */
  color: #ffffff;
}
.subtitleCard1 {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;

  /* shades / white */
  color: #ffffff;
}
.titleCard1 {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;

  /* shades / white */
  color: #ffffff;
}
</style>
